










import { getSimpleOperatorIcon } from '@/common/utils';
import Vue from 'vue'
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'operator-item',
  props: ['operator'],
  methods: {
    handleClick() {
      console.log('s', this.selectedOperator);
      if (this.selectedOperator == this.operator) {
        this.$emit('click', null);
        return;
      }
      this.$emit('click', this.operator);
    }
  },
  computed: {
    ...mapGetters('sales', ['selectedOperator']),
    isSelected() {
      return this.selectedOperator == this.operator;
    },
    logoUrl() {
      return getSimpleOperatorIcon(this.operator.icon);
    },
  }

})
